<template>
  <div class="container">
    <el-table
      highlight-current-row
      style="width: 100%"
      :data="orderData.slice((currentPage - 1) * pageSize, currentPage * pageSize)"
      header-cell-class-name="tableHeader"
    >
      <el-table-column label="订单编号" min-width="15%">
        <template slot-scope="scope">{{ scope.row.order_id }}</template>
      </el-table-column>
      <el-table-column label="订单详情" min-width="35%">
        <template slot-scope="scope">
          <div class="order-detail">
            <el-image style="width: 100px; height: 100px" :src="scope.row.image_url"></el-image>
            <div class="info">
              <span>{{ scope.row.gift_name }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="点值" min-width="8%" prop="point"> </el-table-column>
      <el-table-column label="数量" min-width="8%" prop="count"> </el-table-column>
      <el-table-column label="订单状态" min-width="10%" prop="state"> </el-table-column>
      <el-table-column label="订单时间" min-width="25%" prop="create_at"> </el-table-column>
      <el-table-column label="操作" min-width="10%">
        <template slot-scope="scope">
          <span v-if="scope.row.is_show_ckwl" style="margin-right: 10px">
            <el-link @click="getOrderTarck(scope.row.order_id)">物流</el-link>
          </span>
          <span>
            <span v-if="scope.row.gift_type === 'cj' && (scope.row.state === '弃奖' || scope.row.state === '未中奖')"
              >详情</span
            >
            <el-link v-else @click="goTo('order-detail', scope.row.id, scope.row.is_show_ckwl, scope.row.order_id)"
              >详情</el-link
            >
          </span>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      :data-list="orderData"
      :page-size="5"
      :current-page="1"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    />
    <el-dialog
      title="物流信息"
      :visible.sync="showDialog"
      width="40%"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-timeline :reverse="true" v-if="orderTrack.length">
        <el-timeline-item v-for="(activity, index) in orderTrack" :key="index" :timestamp="activity.msgTime">
          {{ activity.content }}
        </el-timeline-item>
      </el-timeline>
      <div class="orderNoTrack" v-if="orderNoTrack && orderNoTrack.recv_addr">
        <div class="content">
          <div class="left">
            <div>送货地址</div>
          </div>
          <div class="right">
            <div>
              {{ orderNoTrack.recv_addr.province }} {{ orderNoTrack.recv_addr.city }}
              {{ orderNoTrack.recv_addr.county }}
              {{ orderNoTrack.recv_addr.addr }}
              {{ orderNoTrack.recv_addr.receiver }}
              {{ orderNoTrack.recv_addr.tel.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2') }}
            </div>
          </div>
        </div>
        <div class="content">
          <div class="left">
            <div>快递公司</div>
          </div>
          <div class="right">
            <div>{{ orderNoTrack.deliver_name }}</div>
          </div>
        </div>
        <div class="content">
          <div class="left">
            <div>快递单号</div>
          </div>
          <div class="right">
            <div>{{ orderNoTrack.deliver_code }}</div>
          </div>
        </div>
        <div class="other">请根据快递公司单号自行追踪物流</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import store from '@/store'
import pagination from '@/components/pagination'
export default {
  data() {
    return {
      orderData: [],
      showDialog: false,
      orderTrack: [],
      orderNoTrack: {},
      pageSize: 5,
      currentPage: 1,
    }
  },
  components: {
    pagination,
  },
  created() {
    this.getOrderList()
  },
  methods: {
    async getOrderList() {
      store.dispatch('order/getOrderList').then(res => {
        if (res.data.ret_code === 0) {
          this.orderData = res.data.data
        } else {
          this.$message.error(res.data.ret_msg)
        }
      })
    },
    async getOrderTarck(orderCode) {
      this.orderTrack = []
      this.orderNoTrack = {}
      this.showDialog = true
      store.dispatch('order/getOrderTarck', orderCode).then(res => {
        if (res.data.ret_code === 0) {
          if (res.data.data.jdOrderId) {
            this.orderTrack = res.data.data.orderTrack
          } else {
            this.orderNoTrack = res.data.data
          }
        }
      })
    },
    async goTo(name, id, isShowCkwl, orderId) {
      this.$router.push({ name: name, query: { id: id, isShowCkwl: isShowCkwl, orderId: orderId } })
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize
    },
    handleCurrentChange(curtPage) {
      this.currentPage = curtPage
    },
  },
}
</script>
<style lang="less" scoped>
.orderNoTrack {
  margin-top: -15px;
  .content {
    display: flex;
    color: #999;
    line-height: 28px;
    .left {
      color: #999;
      min-width: 56px;
    }
    .right {
      margin-left: 20px;
      color: #000;
    }
  }
  .other {
    line-height: 28px;
  }
}
.container {
  padding: 20px;
  margin: 0;
  background-color: #ffffff;
  box-sizing: border-box;
  /deep/ .el-table__header,
  .el-table__body {
    th,
    td {
      font-size: 14px;
    }
  }
  .order-detail {
    margin-left: -3px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    div.info {
      margin-left: 20px;
      span {
        display: block;
        width: 100%;
        margin: 5px 0 5px 0;
        color: #333333;
        font-size: 14px;
      }
    }
  }
  /deep/ .el-image {
    width: 67px;
    height: 67px;
  }
}
</style>
