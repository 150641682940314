<template>
  <div class="container">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="dataList.length"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  props: {
    dataList: {
      type: Array,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      dataArray: this.dataList,
      curtPage: this.currentPage,
      pageNum: this.pageSize,
    }
  },
  computed: {},
  methods: {
    handleSizeChange: function (size) {
      this.pageNum = size
      this.$emit('handleSizeChange', size)
    },
    handleCurrentChange: function (currentPage) {
      this.curtPage = currentPage
      this.$emit('handleCurrentChange', currentPage)
    },
  },
}
</script>
<style lang="less" scoped>
.container {
  width: 100%;
}
</style>
